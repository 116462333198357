export const SignalsEventTypes = {
    CLICK: 'CLICK',
    ADD_TO_QUOTE: 'CART',
    PURCHASE: 'PURCHASE',
};

export const SignalsSearchSource = {
    FREE_TEXT: 'FREE_TEXT',
    SUGGESTION: 'SUGGESTION',
    CATEGORY_NAV: 'CATEGORY_NAV',
};

export const ProductOrigin = {
    CATALOG: 'CATALOG',
    DIRECT: 'DIRECT',
    STOCK_ORDER: 'STOCK_ORDER',
    INTERCHANGE: 'INTERCHANGE',
    MANUAL: 'MANUAL',
    CROSS_SELL_PR_PRODUCT: 'CROSS_SELL_PR_PRODUCT',
    CROSS_SELL_PT_PRODUCT: 'CROSS_SELL_PT_PRODUCT',
    SUPPLIES: 'SUPPLIES',
    KEYWORD: 'KEYWORD',
};

export const ProductOriginDetails = {
    SEARCH_RESULTS_PART_TYPE: 'SEARCH_RESULTS_PART_TYPE',
};

export const SEARCH_RESULTS_PART_TYPE_NAME = 'SEARCH_PART_TYPE_NAME';

export const ProductKeyType = {
    LEGACY: 'LEGACY',
};

export const ProductSortValues = {
    PRICE: 'PRICE',
    AVAILABILITY: 'AVAILABILITY',
    ASCENDING: 'ASCENDING',
    DESCENDING: 'DESCENDING',
    RELEVANCE: 'SCORE',
};

export const KitLookupTypes = {
    BRAKE: 'BRAKE',
    AC: 'AC',
};

export const PartTypeSearchLimit = {
    FIRST_15: 15,
    ALL: -1,
};

export const EntSearchPartTypeSearchLimit = {
    FIRST_15: 15,
    ALL: 60,
};

export const PartTypeSearchSort = {
    POPULAR: 'rank',
    ALPHA: 'pt_name',
};

export const EntSearchPartTypeSearchSort = {
    POPULAR: 'RANK',
    ALPHA: 'NAME',
};

export const StoreError = {
    STORE_DOWN: 'STORE_DOWN',
};

export const WorksheetError = {
    WORKSHEET_LOCKED: 'WORKSHEET_LOCKED',
};

export const LookupOrigin = {
    TYPEAHEAD: 'typeahead',
    SUGGESTION: 'suggestion',
    CATALOG: 'catalog',
    KEYWORD_PLP_SUGGESTED_PART: 'keywordPlpSuggestedPart',
    RELATED: 'related',
};

export const CatalogTabOrigins = {
    POPULAR: 'POPULAR',
    ALL: 'ALL',
    PACKAGE_JOBS: 'PACKAGEJOBS',
};

export const PlatformId = {
    ALL_PART_TYPES: '05',
    MOST_POPULAR: '99',
    RELATED_PART_TYPES: '90',
};

export const PartTypeRequirements = {
    VEHICLE_REQUIRED: 'BASE_VEHICLE',
};

export const AttributeValues = {
    DONT_KNOW_SHOW_ALL: -99,
};
