import axios from 'axios';
import { isRequestSettled } from '@/common/store/request-status';
import store, { state } from './store/store';
import { HttpStatus } from './constants/httpStatus';
import { ForbiddenReason } from './constants/customErrorCodes';

function currentLanguageInterceptor(config) {
    if (!state.i18n?.locale) return config;
    return {
        ...config,
        headers: {
            ...config.headers,
            'Accept-Language': state.i18n?.locale,
        },
    };
}

/**
 * Adds an 'x-sticky' header to all requests when authenticated to ensure requests go to a single pod in k8s
 * Without this, responses might include cached (and possibly incorrect) data from a different pod
 */
async function stickySessionInterceptor(config) {
    const stickyHeaderEndpointWhitelist = /\/((current|session)\/(user(?!\/shops)|static)|auth\/rest\/refresh|shop\/shopDetails)/;
    // Need to first check for a handful of url's that we can't/don't want to even try to add x-sticky to because they will cause infinite awaiting issues
    if (stickyHeaderEndpointWhitelist.test(config.url) || (state.isSPA && !state.auth)) return config;

    // If we don't have selectedShopId or getAppData is still being requested, then we need to wait till we have those pieces of data
    if (!store.getters.selectedShopId || !isRequestSettled(state.requests.getAppData)) {
        await store.dispatch('requestIfIdle', ['getAppData', 'getUser']);

        // If user is not logged in we can't determine if they can have x-sticky added to their requests or not
        if (!store.getters.isAuthorizedUser) return config;
    }

    // We need the shop also because (1) we use the shop ID as the sticky header and (2) we check the account number to see if it's a demo account.
    if (!state.currentShop?.accountNumber) {
        await store.dispatch('requestIfIdle', ['getCurrentShop']);
    }

    const { stickySessionExcludedAccounts, currentShop } = state;
    // For demo (internal) accounts, we DO NOT want to use the shop ID only because that could overhwelm the k8s pod with team member traffic
    const isIgnoreStickyAccount = stickySessionExcludedAccounts?.includes(String(currentShop?.accountNumber));
    let stickySessionId = store.getters.selectedShopId ?? '';

    if (!stickySessionId || isIgnoreStickyAccount) {
        stickySessionId += state.user.userId;
    }

    return {
        ...config,
        headers: {
            ...config.headers,
            'x-sticky': stickySessionId,
        },
    };
}

async function unauthorizedErrorInterceptor(error) {
    if (error.response?.status !== HttpStatus.UNAUTHORIZED) return Promise.reject(error);

    const isExpiredAuthToken = state.isSPA && state.auth;
    let responseError = error;

    if (isExpiredAuthToken) {
        // Avoid an infinite loop. Did we already retry the reqeuest?
        // Make sure this error isn't the result of an attempt to retry the request.
        const isRefreshTokenRequest = error.config?.url.includes('/auth/rest/refresh');
        const isRetryRequest = !!error.config?.isRetry;
        if (!isRefreshTokenRequest && !isRetryRequest) {
            try {
                await store.dispatch('refreshAuth');

                // With our refreshed access token in hand, let's try the request again.
                return axios({
                    ...error.config,
                    headers: {
                        ...error.config?.headers,
                        Authorization: axios.defaults.headers.common.Authorization,
                    },
                    isRetry: true,
                });
            } catch (retryError) {
                responseError = retryError;
            }
        }

        // A failed request here likely means our tokens are expired, and the user is logged out.
        // Regardless of the cause, something very bad went wrong and we can't authenticate our user.
        // Time to clear out the session.
        store.dispatch('logout');
    }

    return Promise.reject(responseError);
}

/**
 * Checks every 403 error response to see if the user has restrictions placed on them for accepting terms or resetting their password.
 * The base app component can then respond to changes in these values and redirect to the corresponding page so the user can update their info.
 */
async function forbiddenErrorInterceptor(error) {
    if (error.response?.status === HttpStatus.FORBIDDEN) {
        const errorId = error.response?.data?.errorId;

        if (errorId === ForbiddenReason.ACCEPT_TERMS_REQUIRED) {
            store.commit('setTermsAcceptRequired', true);
        } else if (errorId === ForbiddenReason.PASSWORD_RESET_REQUIRED) {
            store.commit('setPasswordChangeRequired', true);
        }
    }

    return Promise.reject(error);
}

axios.interceptors.request.use(currentLanguageInterceptor);
axios.interceptors.request.use(stickySessionInterceptor);
axios.interceptors.response.use((response) => response, unauthorizedErrorInterceptor);
axios.interceptors.response.use((response) => response, forbiddenErrorInterceptor);

axios.defaults.baseURL = __FCO_API_URL__;
