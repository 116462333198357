var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("footer", [
    _c(
      "div",
      {
        staticClass: "content py-5",
      },
      [
        _c(
          "div",
          {
            staticClass: "footer-grid d-print-none mb-5",
          },
          [
            _vm._m(0),
            _vm._v(" "),
            _c("nav", [
              _c(
                "ul",
                {
                  staticClass: "footer-nav",
                },
                [
                  _c("li", [
                    _c(
                      "a",
                      {
                        staticClass: "qaa-footer-msds",
                        attrs: {
                          href: "http://www.3eonline.com/eeeOnlinePortal/DesktopDefault.aspx?id=GFAirWx2BmohQvleYq99kSzCLhParjXcl%2b1aedzg6NTOC%2bBryI7a4gKGBZBru%2fbE&tabid=90",
                        },
                      },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(_vm.fcoM["rs.home.msds"]) +
                            "\n                        "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "li",
                    {
                      staticClass: "d-none d-md-block",
                    },
                    [
                      _c(
                        "FcoLink",
                        {
                          class: [
                            "qaa-footer-credit-application",
                            _vm.creditAppGtmClass,
                          ],
                          attrs: {
                            to: "/credit",
                            href: _vm.fcoUrl("/credit.html"),
                          },
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.fcoM["common.creditApplication"]) +
                              "\n                        "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    {
                      staticClass: "d-none d-lg-block",
                    },
                    [
                      _c(
                        "FcoLink",
                        {
                          staticClass: "qaa-footer-team-viewer",
                          attrs: {
                            to: "/team-viewer",
                            href: _vm.fcoUrl("/teamviewer.html"),
                          },
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.fcoM["rs.home.teamViewer"]) +
                              "\n                        "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.userCanViewStatements
                    ? _c(
                        "li",
                        [
                          _c(
                            "FcoLink",
                            {
                              staticClass: "qaa-footer-billing-statements",
                              attrs: {
                                to: "/admin/statements",
                                href: _vm.fcoUrl(
                                  "/statementlookup/statementview.html"
                                ),
                              },
                            },
                            [
                              _vm.user.earnbackEligible
                                ? _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.fcoM[
                                            "common.statementsAndEarnbacks"
                                          ]
                                        )
                                    ),
                                  ])
                                : _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.fcoM["common.billingStatements"]
                                        )
                                    ),
                                  ]),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "li",
                    [
                      _c(
                        "FcoLink",
                        {
                          class: [
                            "qaa-footer-contactus contactUs",
                            _vm.contactGtmClass,
                          ],
                          attrs: {
                            to: "/help",
                            href: _vm.fcoUrl("/faq.html"),
                          },
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.fcoM["common.contactUs"]) +
                              "\n                        "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.isAuthorizedUser
                ? _c(
                    "ul",
                    {
                      staticClass: "footer-nav",
                    },
                    [
                      _c(
                        "li",
                        [
                          _c(
                            "DotCmsLink",
                            {
                              staticClass: "qaa-footer-drop-box-slip",
                              attrs: {
                                "file-name": "first-call-drop-box-slip.pdf",
                                target: "_blank",
                              },
                            },
                            [_vm._v(_vm._s(_vm.fcoM["rs.home.dropBoxSlip"]))]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        [
                          _c(
                            "DotCmsLink",
                            {
                              staticClass: "qaa-footer-vehicle-inspection-form",
                              attrs: {
                                "file-name": "FCO-INSPECTION_FORM.pdf",
                                target: "_blank",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.fcoM["rs.home.vehicleInspectionForm"]
                                )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.userCanViewLaborClaims
                        ? _c(
                            "li",
                            [
                              _c(
                                "FcoLink",
                                {
                                  staticClass: "qaa-footer-labor-claims",
                                  attrs: {
                                    to: "/admin/labor-claims",
                                    href: _vm.fcoUrl("/admin/laborClaims/"),
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(_vm.fcoM["common.laborClaims"]) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "text-right",
              },
              [
                _c("LanguageSelectButton", {
                  attrs: {
                    "button-class": "footer-language-select",
                    "label-class": "footer-language-select-label",
                  },
                }),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "text-xs footer-legal",
          },
          [
            _c(
              "div",
              {
                staticClass: "med-gray mb-3",
              },
              [
                _vm._v(_vm._s(_vm.copyrightString)),
                _c("br"),
                _vm._v(_vm._s(_vm.versionString)),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "footer-legal-nav d-print-none",
              },
              [
                _c(
                  "FcoLink",
                  {
                    staticClass: "qaa-footer-terms-conditions",
                    attrs: {
                      to: "/terms",
                      href: _vm.fcoUrl("/legal/terms/index.html"),
                    },
                  },
                  [_vm._v(_vm._s(_vm.fcoM["common.termsAndConditions"]))]
                ),
                _vm._v(" "),
                _c(
                  "FcoLink",
                  {
                    staticClass: "qaa-footer-privacy-policy",
                    attrs: {
                      to: "/privacy",
                      href: _vm.fcoUrl("/legal/policy/index.html"),
                    },
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.fcoM["common.privacyPolicy"]) +
                        "\n                "
                    ),
                  ]
                ),
              ],
              1
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass: "footer-logo-container",
      },
      [
        _c("img", {
          staticClass: "footer-logo",
          attrs: {
            src: "/FirstCallOnline/images/fco-logo-white.png",
          },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }