<template>
    <div class="fco-app-content" :class="themeClass">
        <div v-if="isLocalTestProfile && showProfile" class="profile-label align-items-center d-flex py-1 px-3 text-bold">
            <div class="flex-fill text-sm">connected to test resources</div>
            <a href="#" @click.prevent="showProfile = false" class="ml-3 text-lg text-white text-bold text-decoration-none">&times;</a>
        </div>
        <template v-if="!isErrorPage && !disableHeader && !isFullScreen">
            <template v-if="isAuthorizedUser && !hasUserAccessRequirements">
                <BroadcastMessages />
                <AdminBar class="d-none d-lg-block d-print-none" />
                <MainNav />
                <InfoBar v-if="showInfoBar" />
            </template>
            <PublicHeader v-else />
        </template>

        <div class="flex-fill">
            <RouterView v-if="isSPA" />
            <slot v-else />
        </div>

        <FcoFooter v-if="!isErrorPage && !disableFooter && !isFullScreen && !hasUserAccessRequirements" />

        <FcoToast />
        <FcoLoading />
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import AdminBar from '@/common/components/AdminBar.vue';
import MainNav from '@/common/components/MainNav.vue';
import InfoBar from '@/common/components/InfoBar.vue';
import FcoFooter from '@/common/components/Footer.vue';
import PublicHeader from '@/common/components/header/PublicHeader.vue';
import BroadcastMessages from '@/common/components/header/BroadcastMessages.vue';
import { resetUsageTrackingData, loadUsageTracking } from 'fcoModules/usageTrackingService';
import { BgColorPreference } from '../constants/user';
import loginErrorMixin from '../mixins/loginErrorMixin';
import featureFlagsMixin from '../mixins/featureFlagsMixin';
import { isRequestSettled } from '../store/request-status';

export default {
    name: 'BaseApp',
    BgColorPreference,
    props: {
        showInfoBar: {
            type: Boolean,
            default: true,
        },
        disableHeader: {
            type: Boolean,
            default: false,
        },
        disableFooter: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        BroadcastMessages,
        PublicHeader,
        AdminBar,
        MainNav,
        InfoBar,
        FcoFooter,
    },
    mixins: [loginErrorMixin, featureFlagsMixin],
    data() {
        return {
            showProfile: true,
        };
    },
    computed: {
        ...mapState([
            'user',
            'masqueradeSource',
            'requests',
            'isSPA',
            'profile',
            'auth',
            'currentShop',
            'isPasswordChangeRequired',
            'isTermsAcceptRequired',
        ]),
        ...mapGetters(['isMasquerade', 'isAuthorizedUser', 'isAuthExpired', 'isRefreshExpired', 'userDefaultLandingPage']),
        isErrorPage() {
            return this.$route?.name === 'NotFound' || this.$route?.name === 'ErrorForbidden';
        },
        isFullScreen() {
            return this.$route?.matched.some(({ meta }) => meta.isFullScreen);
        },
        themeClass() {
            const theme = this.user?.userPreference?.selectedStylesheet;
            if (theme === BgColorPreference.GRAY) {
                return 'content-subtle-gray';
            }
            return '';
        },
        isLocalTestProfile() {
            return this.profile === 'local-test' || (this.profile === 'test' && __FCO_IS_LOCAL_DEV_SERVER__);
        },
        authStatus() {
            if (this.auth && isRequestSettled(this.requests.getUser)) {
                if (this.isAuthorizedUser) {
                    return 'AUTHORIZED';
                }
                if (this.isPasswordChangeRequired) {
                    return 'RESTRICTED_PASSWORD';
                }
                if (this.isTermsAcceptRequired) {
                    return 'RESTRICTED_TERMS';
                }
            }
            return 'PUBLIC';
        },
        hasUserAccessRequirements() {
            if (!this.isSPA) {
                return window.location.href.includes('/legal/terms/accept.html') || Boolean(this.user?.passwordChangeRequired);
            }
            return this.isPasswordChangeRequired || this.isTermsAcceptRequired;
        },
    },
    methods: {
        /**
         * Set the default locale
         * Check for a locale set by the client (in this order):
         *     1. 'language' query parameter
         *     2. 'locale' set in localStorage
         *     3. browser settings
         */
        async setDefaultLocale() {
            const languageQueryParam = this.isSPA ? this.$route.query.language : new URLSearchParams(window.location.search).get('language');
            this.$store.dispatch('i18n/selectDefaultLocale', languageQueryParam);
        },
        async initializeAuth() {
            if (!this.isSPA) return;

            if (this.isRefreshExpired) {
                this.$store.dispatch('logout');
            } else if (this.isAuthExpired) {
                await this.$store.dispatch('refreshAuth');
            } else {
                this.$store.dispatch('startRefreshTimer');
            }
        },
        async initializeIsCostHidden() {
            await this.$store.dispatch('requestIfIdle', ['getCurrentShop']);
            if (this.currentShop?.hideCosts) {
                this.$store.commit('setIsCostHidden', true);
            }
        },
    },
    watch: {
        async authStatus() {
            if (!this.isSPA) return;

            const isAuthorizedRoute = this.$route.matched.every(({ meta }) => !meta.authorize || meta.authorize());

            if (isAuthorizedRoute) return;

            // Authenticated users without restrictions go to their default landing page
            if (this.isAuthorizedUser) {
                this.$router.push(this.userDefaultLandingPage);
                return;
            }

            if (this.hasUserAccessRequirements) {
                if (this.isPasswordChangeRequired) {
                    this.$router.push('/security-change');
                } else if (this.isTermsAcceptRequired) {
                    this.$router.push('/accept-terms');
                }
                return;
            }

            this.$router.push('/login');
            this.$fcoLoading.removeAll();
        },
        isMasquerade() {
            if (!this.isSPA) {
                const redirectUrl = this.isMasquerade ? this.fcoUrl(this.userDefaultLandingPage) : JSON.parse(this.masqueradeSource); // Wasn't working with the fcoUrl function

                window.location.href = redirectUrl;

                if (!this.isMasquerade) {
                    this.$store.commit('setMasqueradeSource', null);
                }

                return;
            }

            resetUsageTrackingData();

            if (this.isMasquerade) {
                // User has started masquerading
                // Always redirect to the default landing page after starting to masquerade
                this.$router.push(this.userDefaultLandingPage);
                return;
            }

            // When removing the masquerade, try to redirect back to the page the user was on before they started masquerading
            // Make sure the saved value is a valid route. If not, default to the home page.
            const isValidMasqueradeSourceRoute =
                Boolean(this.masqueradeSource) && this.$router.match(this.masqueradeSource).matched.every(({ path }) => path !== '*');
            const unMasqueradeRedirect = isValidMasqueradeSourceRoute ? this.masqueradeSource : '/';
            this.$store.commit('setMasqueradeSource', null);
            this.$router.push(unMasqueradeRedirect);
        },
    },
    async created() {
        await this.initializeAuth();
        this.$store.dispatch('requestIfIdle', ['getAppData']);
        if (this.isAuthorizedUser) {
            if (!this.isSPA) this.$store.dispatch('requestIfIdle', ['getUser']);
            this.initializeIsCostHidden();
            loadUsageTracking();
        }

        await this.setDefaultLocale();

        if (this.loginErrorMessage) {
            const isLoginPage = this.isSPA
                ? this.$route.path === '/login'
                : ['/FirstCallOnline/', '/FirstCallOnline/login.html'].includes(window.location.pathname);
            if (!isLoginPage) {
                this.$fcoToast.error(this.loginErrorMessage);
            }
        }
    },
};
</script>

<style scoped lang="scss">
.fco-app-content {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}
.profile-label {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    background-color: blue;
    border: black 1px solid;
    text-transform: uppercase;
    z-index: 100;
}
</style>
